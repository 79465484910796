<template>
  <div>
    <h4 class="workplatform-title m-t-10 m-b-10">工单列表</h4>
    <Row :gutter="6">
      <i-col span="12" class="m-b-10">
        <Select size="small" v-model="query.assetId" :clearable="true" placeholder="资产" @on-change="handleChangeAsset">
          <Option v-for="(item, index) in companyAssetArray" :key="'asset_' + index" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="12" class="m-b-10">
        <Select v-model="query.stationId" size="small" placeholder="站点" :clearable="true">
          <Option v-for="item in stationArray" :key="'station_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="12" class="m-b-10">
        <DatePicker transfer size="small" class="text-black" v-model="actionDateRange" format="yyyy-MM-dd"
          type="daterange" placeholder="工单作业日期" clearable></DatePicker>
      </i-col>
      <i-col span="12" class="m-b-10">
        <Select v-model="query.qualityTesting" size="small" placeholder="工单状态" :clearable="true">
          <Option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row :gutter="6" class="m-b-10">
      <i-col span="12">
        <Select v-model="query.taskitemType" size="small" placeholder="任务类型" :clearable="true"
          @on-change="handelChangeTaskType">
          <Option v-for="item in taskTypeArray" :key="'t_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="12">
        <Select v-model="query.adType" size="small" placeholder="任务用途" :clearable="true">
          <Option v-for="item in adTypeArray" :key="'ot_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>

    </Row>
    <Row :gutter="8">
      <i-col span="12">
        <i-input v-model="query.keyword" size="small" placeholder="关键字: 站点、客户名称"></i-input>
      </i-col>
      <i-col span="12">
        <Button size="small" type="primary" icon="ios-search" :loading="loadSearch" @click="handelSearch">
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
        <Button type="success" class="m-l-2" size="small" @click="handleDownLoad">下载工单明细</Button>
      </i-col>
    </Row>

    <Row class="table-title">
      <i-col span="7" class="p-l-5">作业时间</i-col>
      <i-col span="7" class="p-l-5">站点</i-col>
      <i-col span="10" class="text-right p-r-5">总数/已完成/已质检</i-col>
    </Row>
    <Row v-if="tableData.list && tableData.list.length === 0">
      <i-col span="24" class="text-center">暂无数据</i-col>
    </Row>
    <Row v-else v-for="(item, index) in tableData.list" class="qc-list" :key="index"
      :class="[(index % 2 === 0) ? 'table-row-1' : 'table-row-2', (item.actionDate === currentChkPlan.actionDate && item.domainId === currentChkPlan.domainId) ? 'qc-list-active' : '']">
      <i-col span="24">
        <div @click="handleChoosePlan(item,true,true)">
          <Row>
            <i-col span="7" class="p-l-5">{{ item.actionDate }}</i-col>
            <i-col span="7" class="p-l-5">
              {{ item.domainName === '' ? '-' : item.domainName }}
            </i-col>
            <i-col span="10"
              class="text-right p-r-5">{{ item.totalCount }}/{{ item.finishCount }}/{{ item.qualityCount }}</i-col>
          </Row>
        </div>
      </i-col>
    </Row>
    <div class="paging_style">
      <Page size="small" :total="tableData.totalRow" :page-size="query.pageSize" show-total show-elevator
        :current="query.pageNumber" @on-change="handlePageNumberChange"></Page>
    </div>
  </div>
</template>

<script>
import { companyMixin } from '@/assets/mixins/company'
import { workorderMixins } from '@/assets/mixins/workorder'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
// import { getPlanPage } from '@/api/msp/plan'
import { ParseDate } from '@/utils/dateFormat'
export default {
  mixins: [companyMixin, workorderMixins],
  props: {
    taskTypeArray: {
      type: Array,
      required: true
    },
    handleDownLoad: {
      type: Function
    }
  },
  data () {
    return {
      companyId: this.$store.getters.token.userInfo.companyId,
      actionDateRange: [],
      query: {
        pageNumber: 1,
        pageSize: 20,
        assetId: null,
        stationId: null,
        // actionDate: null,
        actionStartDate: '',
        actionEndDate: '',
        qualityTesting: null,
        taskitemType: null,
        adType: null
      },

      companyAssetArray: [],
      stationArray: [],
      adTypeArray: [], // 用途类别

      planDataList: [],
      total: 0,
      loadSearch: false,
      currentChkPlan: null
      // taskTypeArray: []
    }
  },
  created () {
    if (this.searchQuery && Object.keys(this.searchQuery).length) {
      this.query = this.searchQuery
      this.actionDateRange = [this.query.actionStartDate, this.query.actionEndDate]
    }
    this.getCompanyAssetArray()
    this.loadPlanList()
  },
  computed: {
    statusArray () {
      return this.$store.state.qualityTesting.statusArray
    },
    curPlanIndex () {
      return this.$store.state.qualityTesting.curPlanIndex
    },
    searchQuery: {
      get () {
        return this.$store.state.qualityTesting.searchQuery
      },
      set (val) {
        this.$store.commit('set_search_query', val)
      }
    },
    tableData: {
      get () {
        return this.$store.state.qualityTesting.planData
      },
      set (val) {
        this.$store.commit('set_plan_data', val)
      }
    }
  },
  methods: {
    handleChangeAsset () {
      this.query.pageNumber = 1
      this.getStationData()
    },
    getStationData () {
      getRimStationByAssetIds({ assetIds: this.query.assetId ? JSON.stringify([this.query.assetId]) : '' }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    // initData () {
    //   // getPlanPage(this.query).then(res => {
    //   //   this.planDataList = res.list
    //   //   this.total = res.totalRow
    //   // })
    //   this.$store.dispatch('getPlanData', this.query)
    // },
    loadPlanList () {
      this.$store.dispatch('getPlanData', this.query)
      // this.searchQuery = this.query
      // getPlanPage(this.query).then(res => {
      //   this.planDataList = res.list
      //   this.total = res.totalRow

      //   if (this.total > 0) {
      //     this.handleChoosePlan(res.list[0])
      //   } else {
      //     this.$store.commit('set_plan_detail_data', {})
      //   }
      // })
    },
    handelSearch () {
      this.query.pageNumber = 1
      this.currentChkPlan = {}
      // this.query.actionDate = (this.startDate === '') ? null : ParseDate(this.startDate)
      this.query.actionStartDate = this.actionDateRange[0] ? ParseDate(this.actionDateRange[0]) : ''
      this.query.actionEndDate = this.actionDateRange[1] ? ParseDate(this.actionDateRange[1]) : ''

      this.loadPlanList()
    },
    handelChangeTaskType () {
      this.query.adType = null
      this.getOrderAdTypeData(this.query.taskitemType).then(res => {
        this.adTypeArray = res
      })
      this.handelSearch()
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.currentChkPlan = {}
      this.loadPlanList()
    },
    handleChoosePlan (planItem, loadPlanDetail = true, initPageNum = false) {
      const planIndex = this.tableData.list.findIndex(x => x.domainId === planItem.domainId && x.actionDate === planItem.actionDate)

      // this.$store.commit('reset_plan_detail_query')
      this.currentChkPlan = planItem || {}
      this.$store.commit('set_cur_domainId', this.currentChkPlan.domainId)
      this.$store.commit('set_cur_plan_key', `${this.currentChkPlan.domainId}_${this.currentChkPlan.actionDate}`)
      this.$store.commit('set_cur_plan_index', `${this.tableData.pageNumber}_${planIndex}`)
      const postData = {
        domainId: this.currentChkPlan.domainId,
        actionDate: this.currentChkPlan.actionDate,
        taskitemType: this.query.taskitemType,
        qualityTesting: this.query.qualityTesting,
        adType: this.query.adType
      }
      if (initPageNum) {
        postData.pageNumber = 1
      }
      // 加载中间详情列表数据
      if (loadPlanDetail) {
        this.$store.dispatch('getPlanDetailData', postData)
      }

      // 加载左侧统计数据
      this.$store.dispatch('getTaskStatusStatData', { taskitemIds: this.currentChkPlan.taskitemIds })
      this.$store.dispatch('getTaskTypeStatData', { taskitemIds: this.currentChkPlan.taskitemIds })
      this.$store.dispatch('getTaskAmountStatData', postData)
    }
  },
  watch: {
    tableData: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.totalRow > 0) {
          if (this.currentChkPlan && val.list.some(x => x.domainId === this.currentChkPlan.domainId && x.actionDate === this.currentChkPlan.actionDate)) {
            this.handleChoosePlan(this.currentChkPlan, false)
          } else {
            let selectedPlan = val.list[0]
            if (!this.currentChkPlan || Object.keys(this.currentChkPlan).length === 0) {
              this.currentChkPlan = {}
            } else {
              if (this.curPlanIndex.length) {
                const pageNumber = parseInt(this.curPlanIndex.split('_')[0])
                const index = parseInt(this.curPlanIndex.split('_')[1])
                if (val.list.length > 1) { // 如果有多条数据
                  if (pageNumber === val.pageNumber) { // 当前页
                    // 最后一条，定位到最后一条，否侧，定位到下一条
                    if (index >= val.list.length - 1) {
                      selectedPlan = val.list[val.list.length - 1]
                    } else {
                      selectedPlan = val.list[index]
                    }
                  } else {
                    // 非当前页，定位到上一页的最后一条
                    selectedPlan = val.list[val.list.length - 1]
                  }
                }
              }
            }

            this.handleChoosePlan(selectedPlan)
          }
        } else {
          this.$store.commit('set_plan_detail_data', {})
        }
      }
    }
  }
}
</script>
