import { getOrderAdType } from '@/api/msp/status'

export const workorderMixins = {
  methods: {
    /**
     * 获取履约用途adType数据
     * @param {Number} taskItemType 任务类型
     * @returns Promise
     */
    getOrderAdTypeData (taskItemType = null) {
      return new Promise((resolve) => {
        getOrderAdType({ type: taskItemType }).then(res => {
          resolve(res)
        })
      })
    }
  }
}
