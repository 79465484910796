<template>
  <div>
    <h4 class="workplatform-title m-t-10 m-b-10">订单列表</h4>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="12">
        <DatePicker
          transfer
          size="small"
          class="text-black"
          v-model="dateRange"
          format="yyyy-MM-dd"
          type="daterange"
          placeholder="订单档期"
          style="width:100%"
          clearable
        ></DatePicker>
      </i-col>
      <i-col span="12">
        <i-input
          v-model="query.keyword"
          size="small"
          placeholder="关键字: 客户名称、品牌、下刊任务名称"
        ></i-input>
      </i-col>
    </Row>
    <Row>
      <i-col span="12">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          :loading="loadSearch"
          @click="handelSearch"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
        <Button
          type="success"
          class="m-l-2"
          size="small"
          @click="handleDownLoad"
        >下载工单明细</Button>
      </i-col>
    </Row>
    <Row class="table-title">
      <i-col
        span="7"
        class="p-l-5"
      >客户</i-col>
      <i-col
        span="7"
        class="p-l-5"
      >品牌</i-col>
      <i-col
        span="10"
        class="text-right p-r-5"
      >订单档期</i-col>
    </Row>
    <Row v-if="orderList.length===0">
      <i-col
        span="24"
        class="text-center"
      >暂无数据</i-col>
    </Row>
    <Row
      v-else
      v-for="(item,index) in orderList"
      class="qc-list"
      :key="'order'+index"
      :class="[(index%2 === 0)?'table-row-1':'table-row-2', (item.orderId===currentChkPlan.orderId&&item.startDate===currentChkPlan.startDate&&item.endDate===currentChkPlan.endDate)?'qc-list-active':'']"
    >
      <i-col span="24">
        <div @click="handleChooseOrder(item,true)">
          <Row>
            <i-col
              span="7"
              class="p-l-5"
            >
              {{item.advertiserName}}
            </i-col>
            <i-col
              span="6"
              class="p-l-5"
            >
              {{item.brandName}}
            </i-col>
            <i-col
              span="11"
              class="text-right p-r-5"
            >{{scheduleFomart(item.startDate,item.endDate)}}</i-col>
          </Row>
        </div>
      </i-col>
    </Row>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handleChangePage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { GetCurrentSchedule, ParseDate } from '@/utils/dateFormat'

import { getAdPlanPage } from '@/api/msp/plan'
export default {
  props: {
    handleDownLoad: {
      type: Function
    }
  },
  data () {
    return {
      dateRange: [],
      query: {
        pageNumber: 1,
        pageSize: 20,
        startDate: '',
        endDate: '',
        keyword: ''
      },
      loadSearch: false,
      actionDateModal: false,
      orderList: [],
      total: 0,
      currentChkPlan: {}
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.query.startDate = this.dateRange[0] ? ParseDate(this.dateRange[0]) : ''
      this.query.endDate = this.dateRange[1] ? ParseDate(this.dateRange[1]) : ''

      getAdPlanPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.orderList = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
          if (res.list.length) {
            this.handleChooseOrder(res.list[0], true)
          } else {
            this.$store.commit('set_plan_detail_data', {})
          }
        } else {
          this.orderList = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handelSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    scheduleFomart (sDate, eDate) {
      return GetCurrentSchedule(sDate, eDate, false)
    },
    handleChooseOrder (item, initPageNum = false) {
      // this.$store.commit('reset_plan_detail_query')
      this.currentChkPlan = item || {}
      const postData = {
        taskIds: item.taskIds,
        // orderId: item.orderId,
        startDate: item.startDate,
        endDate: item.endDate
      }
      if (initPageNum) {
        postData.pageNumber = 1
      }
      this.$store.dispatch('getPlanDetailData', postData)
      // 加载左侧统计数据
      this.$store.dispatch('getAdTaskStatusStatData', postData)
      this.$store.dispatch('getAdTaskTypeStatData', postData)
      this.$store.dispatch('getAdTaskAmountStatData', postData)
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    }
  }
}
</script>
