<style>
.qc-list {
  padding: 5px 0;
  cursor: pointer;
}
.qc-list-active {
  background-color: #4285f4;
}
</style>

<template>
  <div>
    <div class="text-center m-b-10">
      <RadioGroup
        v-model="showType"
        type="button"
        size="small"
        @on-change="handleChangeShowType"
      >
        <Radio :label="1">按作业日期</Radio>
        <Radio :label="2">按客户品牌</Radio>
      </RadioGroup>
    </div>
    <div v-if="showType===1">
      <left-action-date
        :taskTypeArray="taskTypeArray"
        :handleDownLoad="handleDownLoad"
      ></left-action-date>
    </div>
    <div v-else>
      <left-advertiser :handleDownLoad="handleDownLoad"></left-advertiser>
    </div>

    <Modal
      v-model="actionDateModal"
      width="400"
    >
      <p
        slot="header"
        class="text-center"
      >
        <span>选择工单作业日期范围</span>
      </p>
      <div class="m-b-10">
        <Select
          v-model="downloadType"
          size="small"
          :clearable="true"
          placeholder="选择任务类型"
        >
          <Option
            v-for="item in taskTypeArray"
            :key="'type_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </div>
      <div class="text-center">
        <DatePicker
          placeholder="作业日期"
          size="small"
          v-model='downloadActionDate'
          @on-change="changeActionDate"
          type="daterange"
          style="width:100%"
        ></DatePicker>
      </div>
      <div slot="footer">
        <i-button
          type="primary"
          size="small"
          :disabled="disabled"
          @click="completeDownload"
        >确认</i-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import LeftActionDate from './LeftActionDate.vue'
import LeftAdvertiser from './LeftAdvertiser.vue'
import { downloadFileRequest } from '@/utils/download'
import { getTaskitemType } from '@/api/msp/status'

export default {
  mixins: [sysMixins],
  components: {
    LeftActionDate,
    LeftAdvertiser
  },
  data () {
    return {
      companyId: this.$store.getters.token.userInfo.companyId,

      actionDateModal: false, // 下载窗口
      downloadActionDate: '',
      // disabled: true,
      downloadType: null,
      taskTypeArray: []
    }
  },
  created () {
    this.getTaskTypeData()
    this.$store.commit('set_left_page', 'LIST')
  },
  computed: {
    showType: { // 展示方式：1按作业日期，2按客户品牌\
      get () {
        return this.$store.state.qualityTesting.showType
      },
      set (val) {
        this.$store.commit('set_show_type', val)
      }
    },
    disabled () {
      if (this.downloadActionDate.length && this.downloadActionDate[0]) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    handleChangeShowType () {
      // this.$store.commit('set_show_type', this.showType)
      this.$store.commit('reset_plan_detail_query')
      this.$store.commit('set_plan_detail_data', {})
      // 清空右侧统计数据
      this.$store.commit('set_task_status_list', [])
      this.$store.commit('set_task_type_list', [])
      this.$store.commit('set_task_amount_stat_list', {})
    },
    changeActionDate (date) {
      const that = this
      var oneTime = new Date().setTime(new Date(date[0]).getTime())
      var twoTime = new Date().setTime(new Date(date[1]).getTime())
      if (oneTime + 3600 * 1000 * 24 * 93 < twoTime) { // 判断开始时间+30天是否小于结束时间
        that.downloadActionDate = []
        this.$Notice.info({ desc: '作业日期选择范围最大为三个月' })
      } else {
        that.downloadActionDate = date
      }
    },
    getTaskTypeData () {
      getTaskitemType().then(res => {
        if (res && !res.errcode) {
          this.taskTypeArray = res
        } else {
          this.taskTypeArray = []
        }
        this.$store.commit('set_task_type_array', this.taskTypeArray)
      })
    },
    handleDownLoad () {
      this.actionDateModal = true
    },
    completeDownload () {
      if (!this.downloadActionDate || this.downloadActionDate.length === 0 || this.downloadActionDate[0] === '') {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }
      const downloadParams = {
        startDate: this.downloadActionDate[0],
        endDate: this.downloadActionDate[1],
        type: this.downloadType
      }
      const filename = '工单明细(' + downloadParams.startDate + '至' + downloadParams.endDate + ').xlsx'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/downloadplanexcel', downloadParams, filename)
      this.actionDateModal = false
    }
  }
}
</script>
